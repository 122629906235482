import React, { Component } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
export default class BarGraph extends Component {
  render() {
    const { sensor, kleur, type } = this.props;
    var dateformate = "dd-MM";
    if (type === "maand") {
      dateformate = "MMM-YY";
    }
    if (type === "uur") {
      dateformate = "HH:mm";
    }
    function grdata(sensordata) {
      if (sensordata.data) {
        const columns = [
          { type: "datetime", label: "datum" },
          { type: "number", label: "kWh." },
        ];
        const rows = [];
        sensordata.data.map(function (a, key) {
          if (a.datum && a.value) {
            if (type === "dag") {
              rows.push([new Date(a.datum), (((a.value / 1000) * 690) / 1000).toFixed(2) * 24]);
              return a;
            }
            if (type === "maand") {
              var dagenmaand = 0;
              if (moment().month().toString() === moment(a.datum).month().toString()) {
                dagenmaand = moment().date().toString() * 24 + moment().hour();
              } else {
                dagenmaand = moment(a.datum).daysInMonth() * 24;
              }
              rows.push([new Date(a.datum), (((a.value / 1000) * 690) / 1000).toFixed(2) * dagenmaand]);
              return a;
            }
            if (type === "uur") {
              if (sensordata.data[key] && key > 0) {
                rows.push([new Date(a.datum), (((a.value / 1000) * 690) / 1000).toFixed(2)]);
              } 
              return a;
            }
          } else {
            return a;
          }
          
        });
        return [columns, ...rows];
      }
    }
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: dateformate,
          },
          colors: [kleur],
          vAxis: {
            title: "kWh.",
          },

          seriesType: "bars",
          series: { 1: { type: "line" } },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
