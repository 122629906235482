import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = ({ id, value, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "10px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    position: "relative", // Zorgt ervoor dat de knop zich binnen deze container positioneert
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {/* Verwijderknop rechtsboven */}
      <button
        type="button"
        className="btn btn-danger"
        onClick={(e) => {
          e.stopPropagation(); // Voorkomt dat dnd-kit drag event triggert
          onRemove(id);
        }}
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          padding: "5px 10px",
          fontSize: "12px",
        }}
      >
        ×
      </button>

      {/* Dragbaar gedeelte */}
      <div {...listeners} style={{ cursor: "grab" }}>
        <div className="media">
          <div className="media-left">
            <img
              src={value.thumbnail.small}
              alt={value.titel}
              className="media-object"
              style={{
                width: 200,
                height: 150,
                borderRadius: 4,
                boxShadow: "0 1px 3px rgba(0,0,0,.15)",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="media-body">
            <h4 style={{ marginTop: 0 }}>{value.titel}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableItem;