import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import moment from "moment";

class KampeerplaatsLijst extends React.Component {
  state = {
    errors: {},
    load: false,
  };

  render() {
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "naam",
        text: "Naam Sensor",
        formatter: (cellContent, row) => {
          if (cellContent.NL) {
            return cellContent.NL;
          } else {
            return cellContent;
          }
        },
      },
      {
        dataField: "lastcheck",
        text: "Laatste data",
        formatter: (cellContent, row) => {
          if (row.data.length > 0) {
            return moment(row.data[0].datum).fromNow();
          }
        },
      },
      {
        dataField: "lastcheck",
        text: "Meterstand",
        formatter: (cellContent, row) => {
          if (row.data.length > 0) {
            return Number(row.data[0].value).toFixed(2);
          }
        },
      },
      {
        dataField: "data",
        text: "Waardes",
        formatter: (cellContent, row) => {
          const data = [];
          if (row.data.length > 0) {
            row.data.map(function (a, key) {
              data.push(a.value);
            });
          }
          return (
            <Sparklines data={data} margin={5} height={20}>
              <SparklinesLine style={{ strokeWidth: 1.5, stroke: "#d96a29", fill: "none" }} />
              <SparklinesSpots size={2} style={{ stroke: "#d96a29", strokeWidth: 2, fill: "white" }} />
            </Sparklines>
          );
        },
      },
      {
        text: "status",
        dataField: "relais.status",
        headerAlign: "center",
        align: "center",
        headerStyle: { width: "60px" },
        formatter: (cellContent, row) => (cellContent ? <img src="/img/plug_ok.png" width={30} height={30} alt="relais aan" /> : <img src="/img/plug_error.png" width={30} height={30} alt="relais aan" />),
      },
      {
        text: "Bekijken",
        dataField: "soort",
        headerAlign: "center",
        align: "center",
        formatter: (cellContent, row) => {
          return (
            <Link className="btn btn-success" to={{ pathname: "/standplaatsen/kampeerplaatsen/show/" + row._id }}>
              Bekijken
            </Link>
          );
        },
      },
    ];
    const { kampeerplaatsen } = this.props;

    return (
      <ToolkitProvider
        keyField="_id"
        data={kampeerplaatsen}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div>
            <SearchBar placeholder="Zoeken" {...props.searchProps} />
            <hr />
            <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory({ sizePerPage: 20 })} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default KampeerplaatsLijst;
