import React, { Component } from "react";
import { Chart } from "react-google-charts";
export default class BarGraph extends Component {
  render() {
    const { sensor, kleur, type } = this.props;
    var dateformate = "dd-MM";
    if (type === "maand") {
      dateformate = "MMM-YY";
    }
    if (type === "uur") {
      dateformate = "HH:mm";
    }
    function grdata(sensordata) {
      // Added 'type' as a parameter
      if (sensordata.data) {
        const columns = [
          { type: "datetime", label: "datum" },
          { type: "number", label: "kWh" },
        ];
        const rows = [];
        // Changed to forEach for appropriate use
        sensordata.data.forEach(function (a, key) {
          if (a.datum && a.value) {
        
            if (type === "uur" && key > 0) {
              // Adjusted calculation inside push() to correctly parse a.value as a number
              rows.push([new Date(a.datum), (sensordata.data[key - 1].value - parseFloat(a.value)).toFixed(2)]);
            } else if (type === "dag" || type === "maand") {
              rows.push([new Date(a.datum), parseFloat(a.value).toFixed(2)]);
            }
          }
          // Removed the 'else' block that returns nothing as it's unnecessary here
        });
        return [columns, ...rows];
      }
    }

    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: dateformate,
          },
          colors: [kleur],
          vAxis: {
            title: "kWh",
          },

          seriesType: "bars",
          series: { 1: { type: "line" } },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
