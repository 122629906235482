import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  updatevacatursforbedrijf,
  fetchvacatures,
  fetchvacatursforbedrijven,
  fetchvacatursforbedrijf,
} from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import BedrijvenForm from "./Components/edit";

const VacaturesEditBedrijven = ({
  vacature,
  vacatures,
  vacatures_items,
  fetchvacatursforbedrijf,
  updatevacatursforbedrijf,
  bedrijf,
  fetchvacatures,
  fetchvacatursforbedrijven,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [newVacatures, setNewVacatures] = useState([]);
  let navigate = useNavigate();
  const { vitems, id } = useParams();

  // **Eerste useEffect: Data ophalen**
  useEffect(() => {
    const fetchData = async () => {
      try {
        const ddata = await fetchvacatursforbedrijf(vitems, id);
        if (ddata.results) {
          const sdata = await fetchvacatures(vitems);
          if (sdata.results) {
            const vdata = await fetchvacatursforbedrijven(vitems);
            if (vdata.results) {
              setLoaded(true);
            }
          }
        }
      } catch (error) {
        console.error("Fout bij het ophalen van vacatures:", error);
      }
    };

    if (vitems && id) {
      fetchData();
    }
  }, [vitems, id]); // **Dependency-array toegevoegd**

  // **Tweede useEffect: Vacatures filteren**
  useEffect(() => {
    if (vacatures && vacatures.length > 0 && vacatures_items && vacatures_items.length > 0) {
      const bedrijfsVacatureIds = vacatures.map((vture) => vture.vacature?._id);
      const beschikbareVacatures = vacatures_items.filter(
        (vture) => !bedrijfsVacatureIds.includes(vture._id) || (vacature?.vacature?._id && vture._id === vacature.vacature._id)
      );
      setNewVacatures(beschikbareVacatures);
    }
  }, [vacatures, vacature, vacatures_items]);

  // **Submit functie**
  const submit = async (vals) => {
    try {
      const data = await updatevacatursforbedrijf(vitems, id, vals);
      if (data.type === "ERROR_VACATURES") {
        alert(data.payload);
      } else {
        navigate(`/vacatures/bedrijven/${vitems}/vacatures`);
      }
    } catch (error) {
      console.error("Fout bij het updaten van vacature:", error);
    }
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3>Vacatures voor {loaded && vacature?.bedrijven?.promotie?.title ? vacature.bedrijven.promotie.title : "bedrijf"} toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded ? <BedrijvenForm vacatures={newVacatures} onSubmit={submit} /> : <p>Vacatures laden...</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// **Redux state koppelen**
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    vacatures_items: state.vacatures.items,
    bedrijf: state.vacatures.bedrijf,
    vacatures: state.vacatures.vacatures,
    vacature: state.vacatures.vacature,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  updatevacatursforbedrijf,
  fetchvacatures,
  fetchvacatursforbedrijf,
  fetchvacatursforbedrijven,
})(VacaturesEditBedrijven);