import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const Tabel = ({ sensor, categorie }) => {
  const priceFormatter = (cell) => {
    return moment(cell).format("DD-MM-YYYY");
  };

  const valueFormatter = (cellContent) => {
    return cellContent ? cellContent.toFixed(2) : null;
  };

  const differenceFormatter = (cellContent, { voorschot }) => {
    const difference = Number(voorschot) - cellContent;
    const badgeClass = difference < 0 ? "badge badge-danger" : "badge badge-success";
    return <span className={badgeClass}>{difference.toFixed(2)}</span>;
  };

  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      formatter: priceFormatter,
    },
    {
      dataField: "value",
      text: "Verbruik (kWh)",
      formatter: valueFormatter,
    },
  ];
  
  if (categorie.voorschot > 0) {
    columns.push({
      dataField: "value",
      text: `Verschil voorschot ${categorie.voorschot} (kWh)`,
      formatter: (cellContent, row) => differenceFormatter(cellContent, categorie),
    });
  }

  return sensor && sensor.data.length > 0 ? (
    <BootstrapTable
      wrapperClasses="table-responsive-md"
      keyField="datum"
      data={sensor.data}
      columns={columns}
      pagination={paginationFactory()}
    />
  ) : (
    <div>Geen data beschikbaar</div>
  );
};

export default Tabel;