import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { message, fixmessage } from "../../Reducers/loading/actions";
import { getboekingenarrangement, updateboekingen } from "./Reducers/actions";
import ReserveringsOverzicht from "./Components/overzicht";
import ContactForm from "./Components/contact";
import Rodal from "rodal";
import TerugstortModal from "./Components/terugstorten";
import { act } from "react";
import DatumWijzigen from "./Components/datumWijzigen";
const BoekingItemPage = ({ getboekingenarrangement, boeking, updateboekingen, message, fixmessage }) => {
  const [loaded, setLoaded] = useState(false);
  const [action, setAction] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleterugstort, setVisibleterugstort] = useState(false);
  const [count, setCount] = useState(5);
  const countdownIntervalRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.action && loaded && boeking.status === "Nieuw") {
      setAction(params.action);
      setVisible(true);
      countdown();
      if (count === 0) {
        updateboekingen(params.id, { status: params.action }).then((data) => {
          if (data.type === "ERROR_BOEKINGEN") {
            if (data.payload.error && data.payload.error.msg) {
              fixmessage("danger", data.payload.error.msg);
            } else {
              fixmessage("danger", "De boeking is niet succesvol verwerkt");
            }
          } else {
            message(2000, "success", "De boeking is succesvol verwerkt").then(() => {
              navigate("/boekingen/show/" + params.id);
            });
          }
        });
      }
    }
  }, [count, params.id, params.action, loaded]);

  useEffect(() => {
    getboekingenarrangement(params.id).then((sdata) => {
      if (sdata.results) {
        if (sdata?.results?.arrangementdatum?.ervaring) {
          setLoaded(true);
        } else {
          message(6000, "warning", "De boeking is niet gevonden");
        }
      }
    });
  }, [getboekingenarrangement, params.id]);

  const hide = () => {
    setVisible(false);
  };

  const countdown = () => {
    let timer = count;
    countdownIntervalRef.current = setInterval(() => {
      if (timer <= 1) {
        // clear interval when count is 0
        clearInterval(countdownIntervalRef.current);
        setCount(0);
        setVisible(false);
      } else {
        timer -= 1;
        setCount(timer);
      }
    }, 1000);

    // cleanup function, clear interval when component is unmounted
    return () => clearInterval(countdownIntervalRef.current);
  };
  const stopCountdown = () => {
    clearInterval(countdownIntervalRef.current);
    setVisible(false);
  };

  const boekingwijzigen = (action) => {
    updateboekingen(params.id, { status: action }).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        if (data.payload.error && data.payload.error.msg) {
          message(2000, "warning", data.payload.error.msg);
        } else {
          message(2000, "warning", "De boeking is niet succesvol verwerkt");
        }
      } else {
        message(2000, "success", "De boeking is succesvol verwerkt").then(() => {
          if (action === "delete") {
            navigate("/boekingen/arrangement/" + data.results.arrangementdatum._id);
          } else {
            navigate("/boekingen/show/" + params.id);
          }
        });
      }
    });
  };

  if (loaded) {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <div className="box-title">
            <h2>
              {boeking.arrangementdatum.ervaring.naam} <span className="badge badge-secondary">{boeking.status}</span>
            </h2>
          </div>
          <div className="box-tools pull-right">
            <Link to={"/boekingen/ervaring/" + boeking.arrangementdatum._id} className="btn btn-primary btn-sm float-right" role="group">
              Overzicht boekingen
            </Link>
          </div>
        </div>

        <div className="box-body">
          <div className="row">
            <div className="col-md-6">
              <ContactForm ervaring={boeking} klant={boeking.klanten} deelnemers={boeking.deelnemers} />
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="mb-0">Acties</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-5">
                          <h6 className="mb-0">Boeking bevestigen</h6>
                        </div>
                        <div className="col-sm-7 text-secondary">
                          {boeking.status == "cancel" || boeking?.arrangementdatum?.ervaring?.reservering?.accepeteren ? (
                            <button className="btn btn-success" onClick={() => boekingwijzigen("accept")}>
                              <i className="fa-regular fa-thumbs-up"></i> Boeking Accepteren
                            </button>
                          ) : (
                            <i className="fa-regular fa-thumbs-up"></i>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-5">
                          <h6 className="mb-0">Boeking Weigeren</h6>
                        </div>
                        <div className="col-sm-7 text-secondary">
                          {boeking.status !== "cancel" ? (
                            <button className="btn btn-warning" onClick={() => boekingwijzigen("cancel")}>
                              <i className="fa-regular fa-thumbs-down"></i> Boeking Weigeren
                            </button>
                          ) : (
                            <i className="fa-regular fa-thumbs-down"></i>
                          )}
                        </div>
                      </div>
                      <hr />
                      {boeking?.betaling?.status === "open" && (
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0">Boeking Verwijderen</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            <button className="btn btn-danger" onClick={() => boekingwijzigen("delete")}>
                              <i className="fa-solid fa-trash"></i> Boeking Verwijderen
                            </button>
                          </div>
                        </div>
                      )}
                      <DatumWijzigen ervaring={boeking} klant={boeking.klanten} deelnemers={boeking.deelnemers} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ReserveringsOverzicht beschikbaarheid={boeking.arrangementdatum} boeking={boeking} prijzen={boeking.deelnemers} opties={boeking.opties} datum={boeking.datumactiviteit} />
              {boeking.betaling && boeking.betaling.id && (
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="mb-0">Betalingen</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="m-2">Status Betaling</h6>
                        </div>
                        <div className="col-sm-4 text-secondary">
                          {boeking.betaling.status === "paid" ? (
                            <span className="badge badge-success">Betaald</span>
                          ) : (
                            <div>
                              <span className="badge badge-danger">Niet Betaald</span>
                              {moment(boeking.betaling.expiresAt).isAfter() ? <span> Verloopt op {moment(boeking.betaling.expiresAt).format("DD/MM/YYYY HH:mm")}</span> : <span> Is verlopen </span>}
                            </div>
                          )}
                        </div>
                        <div className="col-sm-4 text-secondary">
                          {boeking.betaling.status === "paid" && (
                            <span>
                              {boeking.betaling.amount.value} {boeking.betaling.amount.currency}
                            </span>
                          )}
                        </div>

                        <hr />
                        {boeking.betaling.status === "paid" && (
                          <div>
                            <div className="col-sm-4">
                              <h6 className="m-2">Terugstorten betaling</h6>
                            </div>
                            <div className="col-sm-8 text-secondary">
                              {boeking.betaling.resource === "refund" ? (
                                <span className="badge badge-secondary bdg-lg">Bedrag terugstorten op {moment(boeking.betaling.createdAt).format("DD-MM-yyyy")} </span>
                              ) : (
                                <button
                                  onClick={() => {
                                    setVisibleterugstort(true);
                                  }}
                                  className="btn btn-warning"
                                >
                                  Terugstorten
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      {boeking.betaling.resource === "payment-link" && (
                        <>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Betaallink</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {boeking.betaling._links.paymentLink.href ? (
                                <a href={boeking.betaling._links.paymentLink.href} target="_blank" rel="noopener noreferrer">
                                  Bekijk de betaallink
                                </a>
                              ) : (
                                <span>Er is nog geen betaallink aangemaakt</span>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Rodal visible={visible} onClose={hide} className="rodal-md">
            <div className="card actionmodal">
              <div className="card-header">
                <h4 className="mb-0">Acties</h4>
              </div>
              <div className="card-body">
                {action === "accept" && <h2>De boeking wordt geaccepteerd in {count} seconden.</h2>}
                {action === "cancel" && <h2>De boeking wordt geweigerd in {count} seconden.</h2>}
                <hr />
                <button className="btn btn-warning btn-lg" onClick={stopCountdown}>
                  Bewerking annuleren
                </button>
              </div>
            </div>
          </Rodal>
        </div>
        {boeking.betaling && <TerugstortModal visible={visibleterugstort} boeking={boeking} close={() => setVisibleterugstort(false)} />}
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};

function mapStateToProps(state) {
  return { username: state.auth.user, ervaring: state.ervaringen.item, boeking: state.boekingen.item };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  updateboekingen,
  getboekingenarrangement,
})(BoekingItemPage);
