import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import SelectBox from "../../../../Components/Forms/selectbox";

import PropTypes from "prop-types";

const EditKampeerplaatsen = ({ handleSubmit, sensoren, categorien, relais }) => {
  const requiredValidator = useMemo(() => required(), []);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field
                className="form"
                name="active"
                validate={requiredValidator({ msg: "Type item is verplicht" })}
                label="Standplaats actief"
                component={renderselect}
                options={{
                  true: "Actief",
                  false: "Uitgeschakeld",
                }}
              />
              <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam Kampeerplaats" />
              <Field name="categorie" validate={[required()]} type="input" options={categorien} inputvalue="_id" inputnaam="naam" component={SelectBox} label="Vul de categorie van de kampeerplaats in" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving kampeerplaats (optioneel)" />
              <Field name="device_id" validate={[required()]} type="input" options={sensoren} inputvalue="device_id" inputnaam="naam" component={SelectBox} label="Vul de Sensor ID in" />
              <Field name="relais.id" type="input" options={relais} inputvalue="device_id" inputnaam="naam" component={SelectBox} label="Vul de Relais ID in" />
              <Field
                name="relais.datainput"
                type="input"
                options={{
                  digital_input_1: "digital input 1",
                  digital_input_2: "digital input 2",
                  digital_input_3: "digital input 3",
                  digital_input_4: "digital input 4",
                  digital_input_5: "digital input 5",
                  digital_input_6: "digital input 6",
                  digital_input_7: "digital input 7",
                  digital_input_8: "digital input 8",
                }}
                component={renderselect}
                label="Vul de Relais Digital input"
              />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
          </div>
        </div>
      </form>
    </div>
  );
};

EditKampeerplaatsen.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(EditKampeerplaatsen);

const mapStateToProps = (state) => ({
  initialValues: state.standplaatsen.kampeerplaats, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
