import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class Tabel extends Component {
  render() {
    const { sensor } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }

    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "value",
        text: "Verbruik( kWh)",
        formatter: (cellContent, row) => {
          if (cellContent) {
            return cellContent.toFixed(2);
          } else {
            return null;
          }
        },
      },
      {
        dataField: "value",
        text: "Verschil voorschot " + sensor.categorie[0].voorschot + " ( kWh)",
        formatter: (cellContent, row) => {
          const message = Number(sensor.categorie[0].voorschot) - cellContent < 0 ? false : true;
          if (message) {
            return <span className="badge badge-success">{(sensor.categorie[0].voorschot - cellContent).toFixed(2)}</span>;
          } else {
            return <span className="badge badge-danger">{(sensor.categorie[0].voorschot - cellContent).toFixed(2)}</span>;
          }
        },
      },
    ];
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="datum" data={sensor.data} columns={columns} pagination={paginationFactory()} />;
  }
}
