import React, {  } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { renderField } from "../../../../Components/Forms/renders";
import UploadFormSingle from "../../../../Components/Forms/uploadsingle";
import RichTextMarkdown from "../../../../Components/Forms/wysiwyg";

const BedrijvenForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
        <div className="box box-default p-2 m-3">
            <h3 className="box-header">Promotie</h3>
            <Field name="promotie.title" type="input" component={renderField} label="Kies een title" validate={[required()]} />
            <Field name="promotie.discription" type="input" component={RichTextMarkdown} label="Vul korte bedrijving van je bedrijf" validate={[required()]} />
            <Field name="promotie.image" type="input" validate={[required()]} component={UploadFormSingle} label="Kies een foto" />
            <Field name="promotie.vacatureurl" type="input" component={renderField} label="Vacature url van het bedrijf" />
          </div>
          <div className="box box-default p-2 m-3">
            <h3 className="box-header">Contactpersoon</h3>
            <Field name="contactpersoon.naam" type="input" component={renderField} label="Naam" />
            <Field name="contactpersoon.email" type="input" component={renderField} label="Email" />
            <Field name="contactpersoon.telefoon" type="input" component={renderField} label="Telefoon" />
          </div>
        </div>
      </div>
      <div className="pull-left submit">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanmaken" />
      </div>
    </form>
  );
};
let ReduxBedrijvenForm = reduxForm({
  form: "bedrijf_vac_edit", // een unieke identifier voor dit formulier
})(BedrijvenForm);

ReduxBedrijvenForm = connect(
  (state) => ({
    initialValues: state.vacatures.bedrijf, // haal initiële waarden uit account reducer
  })
  // bind account loading action creator (je kunt hier acties binden indien nodig)
)(ReduxBedrijvenForm);

export default ReduxBedrijvenForm;
