import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchkampeerplaats, getsensor_dag, getsensor_maand, getsensor_uur } from "../Reducers/actions";
import { fixmessage } from "../../../Reducers/loading/actions";
import moment from "moment";
import BarGraph from "./Components/graphelectra";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import { Link, useParams } from "react-router-dom";
import Tabel from "./Components/tabelelectra";
import FilterSensor from "./Components/filterelect";
const Kampeerplaats = (props) => {
  const [load, Setload] = useState(false);
  const [datum, Setdatum] = useState({ startdate: moment().subtract(14, "days").toDate(), enddate: new Date() });
  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
  });
  const [datum_month] = useState({ startdate: moment().subtract(12, "months").toDate(), enddate: new Date() });
  const [datum_day, Setdatum_day] = useState({ startdate: moment().subtract(14, "days").toDate(), enddate: new Date() });
  const [gemiddelde, Setgemiddelde] = useState(0);
  const [stand, setStand] = useState(0);
  const [standdag, setStandDag] = useState(0);

  const [max, Setmax] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    getvals()
      .then(() => getvals_dag())
      .then(() => getvals_maand())
      .then(() => getvals_uur())
      .then(() => Setload(true));
  }, [datum, datum_hour, datum_day]);

  function changedata(data) {
    if (data.datum.enddate && data.datum.startdate) {
      const timer = setTimeout(() => {
        Setdatum_day(data.datum);
      }, 1000);
    }
  }

  function getvals() {
    return new Promise((resolve, reject) => {
      props.fetchkampeerplaats(id, datum).then((data) => {
        if (data.results) {
          resolve();
        } else {
          props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        }
      });
    });
  }

  function getvals_dag() {
    return new Promise((resolve, reject) => {
      props.getsensor_dag(id, datum_day).then((data) => {
        if (!data.results) {
          props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          Setgemiddelde(data.results.data.map((element) => element.value).reduce((a, b) => a + b, 0) / data.results.data.length);
          Setmax(Math.max(...data.results.data.map((o) => o.value)));
          setStandDag(data.results.data.reduce((acc, curr) => acc + curr.value, 0).toFixed(2));
          resolve();
        }
      });
    });
  }

  function getvals_maand() {
    return new Promise((resolve, reject) => {
      props.getsensor_maand(id, datum_month).then((data) => {
        if (!data.results) {
          props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          resolve();
        }
      });
    });
  }

  function getvals_uur() {
    return new Promise((resolve, reject) => {
      props.getsensor_uur(id, datum_hour).then((data) => {
        if (!data.results) {
          props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          if (data.results.data && data.results.data[0]) setStand(data.results.data[0].value.toFixed(2));
          resolve();
        }
      });
    });
  }
  function graph(row) {
    const data = [];
    if (row.data.length > 0) {
      row.data.map(function (a, key) {
        if (key > 0) {
          data.push(Number(row.data[key - 1].value - Number(a.value)));
        }
        return a;
      });
    }
    return (
      <Sparklines height={120} data={data.reverse()}>
        <SparklinesLine color="#848c45" limit={20} />
        <SparklinesSpots />
      </Sparklines>
    );
  }

  function getKwh(data) {
    return data && data.length > 1 ? (data[0].value - data[1].value).toFixed(2) : 0;
  }

  function luodate(data) {
    if (data && data[0] && data[0].datum) {
      return moment(data[0].datum).add(2, "hours").fromNow();
    } else {
      return null;
    }
  }

  const { kampeerplaats } = props;
  return (
    <div className="box box-default show-elecra">
      <div className="box-header with-border">
        <h3 className="box-title">
          {kampeerplaats.kampeerplaats.naam}{" "}
          {kampeerplaats.kampeerplaats?.relais?.status ? (
            <span>
              <img src="/img/plug_ok.png" width={30} height={30} alt="relais aan" />
            </span>
          ) : (
            <span>
              <img src="/img/plug_error.png" width={30} height={30} alt="relais aan" />
            </span>
          )}
        </h3>
        <div className="box-tools pull-right">
          <Link className="btn btn-warning btn-sm float-right" role="group" to={"/standplaatsen/kampeerplaatsen/edit/" + kampeerplaats.kampeerplaats._id}>
            Wijzigen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor changedata={changedata} verbruik={standdag} gemiddelde={gemiddelde} max={max} stand={stand} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className=" box-primary">
          <div className="box-body" />
          {load && (
            <div className="row m-1">
              <div className="col-12">
                <h3 className="p-0">
                  Gegevens metingen van {moment(datum_day.startdate).format("D MMMM")} tot {moment(datum_day.enddate).format("D MMMM")}{" "}
                </h3>
              </div>
              <div className="col-12 col-md-4 col-lg-3 p-0">
                <div className="card p-4 h-90">
                  <div className="valuekwh">
                    <span className="valuweKwh_stand"> {getKwh(kampeerplaats.uur.data)}</span> kWh
                  </div>
                  {graph(kampeerplaats.dag)}
                  <div className="details mt-2">Laatste meting {luodate(kampeerplaats.uur.data)}</div>
                </div>
              </div>
              <div className="col-12 col-md-10 col-lg-9 p-0">
                <Tabel sensor={kampeerplaats.dag} />
              </div>
              <div className="col-12">
                <BarGraph sensor={kampeerplaats.dag} kleur="#B1BF41" type="dag" />
              </div>

              <div className="col-12 col-md-12 col-lg-12 p-0">
                <div className="box-titel">
                  <h3 className="p-4">Metingen 24 uur</h3>
                </div>

                <BarGraph sensor={kampeerplaats.uur} kleur="#D96A29" type="uur" />
              </div>
              <div className="col-12 col-md-12 col-lg-12 p-0">
                <h3 className="p-4">Metingen per maand</h3>
                <BarGraph sensor={kampeerplaats.maand} kleur="#D96A29" type="maand" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { sensor: state.sensoren, kampeerplaats: state.standplaatsen };
}

export default connect(mapStateToProps, { fetchkampeerplaats, fixmessage, getsensor_dag, getsensor_maand, getsensor_uur })(Kampeerplaats);
