import React, { useState, useEffect } from "react";
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/scss/styles.scss";

const SelectBox = (props) => {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (typeof props.input.value === "string") {
      setValue(props.input.value);
    } else if (typeof props.input.value === "object") {
      console.log(props.input.value);
      setValue(props.input.value[props.inputvalue]);
    }
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    return () => {
      setValue([]);
    };
  }, []);

  const handleSelect = (value) => {
    const newValue = value[props.inputvalue];
    setValue(newValue);
    console.log(newValue);
    props.input.onChange(newValue);
  };

  const {
    label,
    meta: { error, touched, warning },
  } = props;
  if (label) {
    return (
      <div>
        <label>{label}</label>
        <div>
          <DropdownList dataKey={props.inputvalue} textField={props.inputnaam} data={options} onChange={handleSelect} value={value} filter="contains" />
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <DropdownList dataKey={props.inputvalue} textField={props.inputnaam} data={options} onChange={handleSelect} value={value} filter="contains" />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }
};
export default SelectBox;
