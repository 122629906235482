import React, { useState, useEffect } from "react";
import moment from "moment";
const FilterSensor = ({ loading, gemiddelde, max, verbruik, accommodatie, index, soort, element }) => {
  const [gemiddeldeState, setGemiddeldeState] = useState(0);
  const [verbruikState, setVerbruikState] = useState(0);
  const [maxState, setMaxState] = useState(0);
  const [showVoorschot, setshowVoorschot] = useState(false);
  useEffect(() => {
    if (loading) {
      setGemiddeldeState(gemiddelde.toFixed(2));
      setVerbruikState(verbruik);
      setMaxState(max.toFixed(2));
      if (element.categotie && element.categotie.voorschot && element.categotie.voorschot > 0) {
        setshowVoorschot(true);
      }
    }
  }, [gemiddelde, max, verbruik, loading]);

  const welkemeter = () => {
    if (soort === "water") {
      return "Kub";
    }
    return "kWh";
  };

  return (
    <div className="container filter">
      <div className="row d-flex flex-wrap">
        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Totaal Verbruik
          <span className="gemiddelde">
            {verbruikState} {welkemeter()}
          </span>
        </div>

        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Gemiddelde (per dag)
          <span className="gemiddelde">
            {gemiddeldeState} {welkemeter()}
          </span>
        </div>
        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Hoogste Piek (per dag)
          <span className="gemiddelde">
            {maxState} {welkemeter()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FilterSensor;
