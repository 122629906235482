import React, { useState } from "react";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';

const TimePickerSelect = (props) => {
  const [time, setTime] = useState(props.input.value || "00:00");

  const handleSelect = (val) => {
    setTime(val);
    props.input.onChange(val);
  };

  const { label, error, touched, warning } = props;

  return (
    <div>
      {label && <label>{label}</label>}
      <div>
        <TimePicker
          onChange={handleSelect}
          value={time}
          disableClock
          format="HH:mm"
        />
        {touched && (
          (error && <span className="text-danger">{error}</span>) ||
          (warning && <span>{warning}</span>)
        )}
      </div>
    </div>
  );
};

export default TimePickerSelect;