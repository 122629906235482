import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import PropTypes from "prop-types";
import SwitcButton from "../../../../Components/Forms/switch";

const SensorEditForm = ({ handleSubmit }) => {
  const requiredValidator = useMemo(() => required(), []);

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field name="naam" type="input" validate={[requiredValidator]} component={renderField} label="Naam standplaatscategorie" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving standplaatscategorie (optioneel)" />
              <Field name="prijs" type="number" component={renderField} label="Prijs per kWh per nacht" />
              <Field name="voorschot" type="number" component={renderField} label="Voorschot in kWh per nacht" />
              <Field name="stekker" type="input" component={SwitcButton} label="Stekker verplicht aanwezig?" />
              <Field name="limiet" type="input" component={SwitcButton} label="Stroom automatisch uitschakelen bij overschrijding van het toegestane verbruik?" />
              <Field name="amperage" type="number" component={renderField} label="Maximale stroomsterkte (Ampère)" />
              <Field name="load_balancing" type="input" component={SwitcButton} label="Load balancing activeren?" />
              <Field name="max_energieverbruik" type="number" component={renderField} label="Maximaal toegestaan energieverbruik per dag (kWh)" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
          </div>
        </div>
      </form>
    </div>
  );
};

SensorEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(SensorEditForm);

const mapStateToProps = (state) => ({
  initialValues: state.standplaatsen.categorie, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
