import React from "react";

const Invoice = ({ invoiceData }) => {
  return (
    <div className="container mt-5">
      <p>
        <strong>Naam:</strong> {invoiceData.customerName}
      </p>
      <p>
        <strong>Kampeerplaats:</strong> {invoiceData.kampeerplaats}
      </p>
      <p>
        <strong>Aankomstdatum:</strong> {invoiceData.arrivalDate}
      </p>
      <p>
        <strong>Vertrekdatum:</strong> {invoiceData.departureDate}
      </p>
      <hr />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Omschrijving</th>
            <th scope="col">Waarde</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Verbruik</td>
            <td>{invoiceData.usage} kWh</td>
          </tr>
          <tr>
            <td className="pt-3">Voorschot</td>
            <td className="pt-3">{invoiceData.advanceKwH} kWh</td>
          </tr>
          <tr>
            <td className="pt-3">Verschil in verbruik</td>
            <td className="pt-3">{invoiceData.verrekengebruik} kWh</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td>Prijs per kWh</td>
            <td>€{invoiceData.unitPrice}</td>
          </tr>
          <tr>
            <td className="pt-3"><strong>Te betalen / Terug te ontvangen</strong></td>
            <td className="pt-3">
              <strong>€{invoiceData.balance}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Invoice;
