import React, { useState, useEffect } from "react";
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, KeyboardSensor } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "./item";

const SortableComponent = ({ input }) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    const enhancedValue = (input.value || []).map((item, index) => ({
      ...item,
      id: item.id || item.image || `item-${index}`,
    }));
    setValue(enhancedValue);
  }, [input.value]);

  const handleRemove = (index) => {
    setValue((prevValue) => {
      const updatedValue = [...prevValue];
      updatedValue.splice(index, 1);
      input.onChange(updatedValue);
      return updatedValue;
    });
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return;

    const oldIndex = value.findIndex((item) => item.id === active.id);
    const newIndex = value.findIndex((item) => item.id === over.id);

    if (oldIndex !== -1 && newIndex !== -1) {
      const updatedValue = arrayMove(value, oldIndex, newIndex);
      setValue(updatedValue);
      input.onChange(updatedValue);
    }
  };

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={value.map((item) => item.id)} strategy={verticalListSortingStrategy}>
        <ul>{value.map((item, index) => (item?.thumbnail?.small ? <SortableItem key={item.id} id={item.id} idx={index} value={item} onRemove={() => handleRemove(index)} /> : null))}</ul>
      </SortableContext>
    </DndContext>
  );
};

export default SortableComponent;
